import React, { useState } from 'react';
import './App.css';
import Logo from '../images/Logo.png';

const App = () => {

    const [email, setEmail] = useState('');

    return (
        <div className="main">
            <img src={Logo} alt="Logo"/>
            <h1>DORM ROOM PODCAST</h1>
            <p>COMING 2021</p>
            <form action="https://dormroompodcast.us17.list-manage.com/subscribe/post" method="POST" noValidate>
            <div className="ui action input">
                <input name="EMAIL" type="text" placeholder="ethan@hansen.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <button className="ui button">SUBSCRIBE</button>
            </div>
                <input type="hidden" name="u" value="5d07fdce350a9b28a3e7fcc46"/>
                <input type="hidden" name="id" value="03e7178747"/>
            </form>
        </div>
    );
}

export default App;